import { create } from 'zustand'

const updateLabelsState = (labels, state, key) => ({
  ...state,
  [key]: labels,
  labels: { ...state.labels, ...(labels || {}) },
})

export const useLabelsStore = create(set => ({
  labels: {},
  pageLabels: {},
  popupLabels: {},
  setLabels: labels => set({ labels }),
  addLabels: labels => set(state => ({ ...state, labels: { ...state.labels, ...(labels || {}) } })),
  setPopupLabels: labels => set(state => updateLabelsState(labels, state, 'popupLabels')),
  setPageLabels: labels => set(state => updateLabelsState(labels, state, 'pageLabels')),
}))

export const setLabelsToStore = labels => useLabelsStore.setState({ labels })
export const addLabelsToStore = labels =>
  useLabelsStore.setState(state => ({ ...state, labels: { ...state.labels, ...(labels || {}) } }))
export const setPopupLabelsToStore = labels =>
  useLabelsStore.setState(state => updateLabelsState(labels, state, 'popupLabels'))
export const setPageLabelsToStore = labels =>
  useLabelsStore.setState(state => updateLabelsState(labels, state, 'pageLabels'))
